import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import richText from "../../utils/richText"
import PrimaryButton from "../common/PrimaryButton"
import { Dromme, FeaturedHeading, FeaturedLarge } from "../typography"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding: 48px 24px;

  max-width: 1440px;
  margin: 0 auto;

  position: relative;

  // border-bottom: 1px solid ${colors.creamLight};

  &:after {
    content: "\A";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    flex-direction: ${(props: any) => (props.flipped ? "row-reverse" : "row")};
    padding: 0;
  }
`

const PhotoWrapper = styled.div`
  margin-bottom: 40px;

  @media ${breakpoints.tablet} {
    flex: 1 1 44%;
    margin: 0;
    border-right: ${(props: any) =>
      props.flipped ? "0" : `1px solid ${colors.creamLight}`};

    border-left: ${(props: any) =>
      props.flipped ? `1px solid ${colors.creamLight}` : "0"};
    padding: 40px;

    box-sizing: border-box;

    .gatsby-image-wrapper {
      width: 560px;
      height: 560px;

      display: block;
      margin: 0 auto;
    }
  }
`

const ProductWrapper = styled.div`
  color: ${colors.creamLight};

  ${FeaturedLarge} {
    display: block;

    margin-top: 4px;
    margin-bottom: 24px;
  }

  ${PrimaryButton} {
    margin: 32px 0 0;
    color: ${colors.firDark};
  }

  @media ${breakpoints.tablet} {
    flex: 1 1 56%;
    padding: 32px 40px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`

const ProductDescription = styled.div`
  @media ${breakpoints.tablet} {
    max-width: 400px;
  }
`

export default function Product({ product, flipped }) {
  return (
    <Wrapper flipped={flipped}>
      <PhotoWrapper flipped={flipped}>
        <GatsbyImage
          image={product.productImage?.gatsbyImage}
          alt={`Product Image of ${product.productTitle}`}
        />
      </PhotoWrapper>
      <ProductWrapper>
        <div>
          <Dromme>Drømme</Dromme>
          <FeaturedLarge>{product.productTitle}</FeaturedLarge>
        </div>
        <div>
          <ProductDescription>
            {richText(product.productDescription?.raw)}
          </ProductDescription>
          <PrimaryButton as={Link} to={`/products/${product.slug}`}>
            Buy Now
          </PrimaryButton>
        </div>
      </ProductWrapper>
    </Wrapper>
  )
}
